/*--------head------------*/
.head {
  padding: 20px 0;
  color: #000000;
}
.logo h1 {
  font-size: 35px;
  font-weight: 900;
  z-index: 1;
}
.head .icon {
  margin-left: 10px;
}
/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 30px;
  z-index: 1;
}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
  list-style-type: none;
}
header ul {
  padding: 30px 20px;
  list-style-type: none;
}
header ul li a {
  color: #000000;
  font-weight: 500;
  transition: 0.5s;
  list-style-type: none;
}
header ul li a.white-text {
  color: #ffffff;
  font-size: 100%;
  font-weight: bold;
}

header ul li a.black-text {
  color: #000000;
  font-size: 100%;
  font-weight: bold;
}
header ul li a:hover {
  color: #dda523;
  border-bottom: 1px solid #272626;
}

/*Get certificate button*/
.start {
  background-color: #FFD95A;
  z-index: 1;
  padding: 30px 70px;
  color: #000000;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}

.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  left: -2%;
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #000000;
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  .start {
    clip-path: none;
    display: none;
  }
  .social-media-div {
    display: none;
  }
  .logo {
    display: flex;
    align-items: center;
    margin-top: 10%;
  }
  .logo h1 {
    font-size: 32px;
    font-weight: 900;
  }
  .penguin-logo {
    width: 55px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
    margin-right: 1%;
    margin-left: -5%;
    margin-top: 4%;
  }
  header {
    margin: 0;
    background-color: #FFD95A;
    position: relative;
    color: #000000;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li{
    margin-bottom: 20px;
    list-style-type: none;
    color: #000000;
  }
  header ul li a{
    color: #000000!important;
  }
  .toggle {
    display: block;
    background: #ffffff;
    color: black;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: -130px;
    padding-left: 1px;
  }
  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #FFD95A;
    margin-top: -75px;
  }
}
