@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  background-color: #f8f8f8;
}

.logo {
  display: flex;
  align-items: center;
}

.penguin-logo {
  width: 65px; /* Adjust size as needed */
  height: 90px; /* Adjust size as needed */
  margin-right: 1%;
  margin-left: -20%;
  margin-top: 2%;
}
/*------------global-----------*/
.container {
  max-width: 85%;
  margin: auto;
}
.flexSB {
  display: flex;
  justify-content: space-between;
  z-index: 1;
}
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(138, 135, 135, 0.3);
  color: #000000;
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: #ff5a5a;
  color: #000000;
}
a {
  text-decoration: none;
  color: #000000;
} 
li {
  list-style-type: none;
}
.row {
  width: 50%;
}
button {
  padding: 17px 30px;
  background-color: #faf8f8;
  color: #000000;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;
}
button i {
  margin-left: 20px;
}
button:hover {
  box-shadow: none;
}
.primary-btn {
  background-color: #FFD95A;
  color: #000000;
}

/*------------global-----------*/
#heading {
  text-align: center;
  padding: 40px 0;
}
#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #3b3b3b;
  text-transform: uppercase;
}
#heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
}
p {
  line-height: 30px;
  font-size: 18px;
}

/*------------new back-----------*/

.back ul {
  position: relative;
  display: flex;
  gap: 30px;
  justify-content: center;
  
}
.back ul li {
  position: relative;
  list-style: none;
}
.back ul li a {
  font-size: 100px;
  text-decoration: none;
  letter-spacing: 2px;
  line-height: 100%;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
  margin: 0;
  
}
.back ul li a::before {
  content: attr(data-text);
  position: absolute;
  color: var(--clr);
  width: 0;
  overflow: hidden;
  transition: 1s;
  border-right: 5px solid var(--clr);
  -webkit-text-stroke: 1px var(--clr);
  margin-left: 5.5%;
}
.back ul li a:hover::before {
  width: 90%;
  filter: drop-shadow(0 0 25px var(--clr))
}


.back {
  background-image: url("../src/assests/home/back.webp");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: 30%;
  color: #fff;
  text-align: center;
  opacity: 0.95;
}
/*-----------------------------*/
.margin {
  margin-top: 40.3%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.flex {
  display: flex;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}
.padding {
  padding: 80px 0;
}
.shadow {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .grid2,
  .grid3,
  .grid {
    grid-template-columns: repeat(2, 1fr);
    display: flex;
    flex-direction: column;
  }
  .back {
    background-position: center;
    padding-top: 50%;
    height: 60vh;
  }
  .back ul li a {
    font-size: 70px;
    margin-top: 50px;
    margin-left: 10px;
  }
  .container #heading h1,h3 {
    position: relative;
    line-height: 50px;
  }
}
/* Adjustments for custom scrollbar */
/* Hide default scrollbar */
body::-webkit-scrollbar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 5px;
  background-color: transparent; /* Transparent background */
  z-index: 9999; /* Ensure it appears above other elements */
}

/* Track */
body::-webkit-scrollbar-track {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: transparent; /* Transparent background */
}

/* Handle */
body::-webkit-scrollbar-thumb {
  width: 8px;
  height: 2px;
  background-image: linear-gradient(to bottom, #0bd1ff, #ffa3ff, #ffd24e);
  border-radius: 15px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}