/*-------------services---------*/
.services {
  text-align: center;
}
.services .box {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);;
  padding: 30px 20px;
  transition: 0.5s;
}
.services .img {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
}
.services img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.services h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
  line-height: 30px;
}
.services .box .show {
  opacity: 0;
}
.services .box:hover {
  background-color: #FFD95A;
  border-radius: 5px;
  cursor: pointer;
  transform: translateY(-20px);
}
.services .box:hover:before {
  opacity: 1;
}
.services .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.services .box:hover .img {
  display: none !important;
  position: absolute;
  top: 0;
  left: 0;
}
.services .box:hover h1 {
  color: #000000;
  opacity: 1;
  transform: translateY(0px);
}

.white {
  background-color: rgb(255, 255, 255);
}

.black {
  background-color: rgb(44, 43, 43);
}

.black-text {
  color: black;
  font-size: 13px;
}

.white-text {
  color: white;
  font-size: 13px;
}

.line {
  width: 80%;
  height: 1px;
  background-color: #969494;
  margin: 1px 0;
  position: relative;
  margin-left: 50px;
  margin-top: 35px;
}
.services .box .description {
  display: none;
}

.services .box:hover .description {
  display: block;
  color: rgb(0, 0, 0);
  text-align: left;
  text-align: center;
}

/*   sticky button  */
.sticky-button {
  position: fixed;
  top: 50%; /* Adjust as needed */
  right: -20px; /* Adjust as needed */
  transform: translateY(-50%);
  z-index: 1000; /* Ensure the button stays above other content */
}

.sticky-button button {
  background-color: #FFD95A;
  color: rgb(0, 0, 0);
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .sticky-button {
    display: none;
  }

  .container {
    margin-top: -35px;
  }
}