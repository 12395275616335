.blog img {
  width: 100%;
  height: 100%;
}
.blog .text {
  padding: 40px 30px;
}
.blog h1 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin: 20px 0;
  transition: 0.5s;
}
.blog h1:hover {
  color: #dda523;
  cursor: pointer;
}
.blog span {
  text-transform: uppercase;
  font-weight: 500;
  color: #dda523;
}
.blog span label {
  margin-left: 10px;
  color: grey;
  font-size: 14px;
}
.blog span:nth-last-child(1) label {
  color: #dda523;
}
.blog p {
  color: grey;
}

.blog pre {
  white-space: pre-wrap;       /* CSS3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* IE 5.5+ and CSS3 */
  overflow-x: auto;            /* Horizontal scrollbar for overflow content */
  max-width: 100%;             /* Ensure it fits within the container */
  padding: 1rem;               /* Optional: Add some padding for better readability */
  background-color: #f5f5f5;   /* Optional: Background color for distinction */
  border-radius: 5px;          /* Optional: Rounded corners */
}

particles-js{
  width: 100%;
  height: 100%;
  background: #111111;
}