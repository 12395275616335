.social-media-div {
  font-size: 2em;
  margin-right: -90px;
  margin-top: 1%;
}

.icon-button i {
  color: white;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  height: 2.6rem;
  line-height: 2.6rem;
  margin: 0 5px;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 2.6rem;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out; /* Added transition for smoother hover effect */
  z-index: 1;
}

.icon-button {
  margin-bottom: 10px;
}

.linkedin i {
  background-color: #0e76a8;
}

.linkedin i:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px #0e76a8;
}

.github i {
  background-color: #333;
}

.github i:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px #333;
}

.gitlab i {
  background-color: #ffcc52;
}

.gitlab i:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px #333;
}

.google i {
  background-color: #ea4335;
}

.google i:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px #ea4335;
}

.twitter i {
  background-color: #1da1f2;
}

.twitter i:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px #1da1f2;
}

.instagram i {
  background-color: #e4405f;
}

.instagram i:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px #e4405f;
}

.youtube i {
  background-color: red;
}

.youtube i:hover {
  color: rgba(255, 255, 255, 1);
  box-shadow: 0 5px 15px red;
}

/* Media Query */
@media (max-width: 768px) {
  .social-media-div {
    text-align: center;
    margin-right: 0;
  }
}
