.hero {
  background-image: url("../../../assests/home/line.jpg");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  color: #000000;
}
.hero #heading h3 {
  color: #000000;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}

.arrows {
  width: 60px;
  height: 72px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 20px;
}

.arrows path {
  stroke: #000000;
  fill: transparent;
  stroke-width: 1px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
  animation-delay:-1s;
  -webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay:-0.5s;
  -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay:0s;
  -webkit-animation-delay:0s; /* Safari 和 Chrome */
}

@media screen and (max-width: 768px) {
  .margin {
    height: 30vh;
    width: auto;
  }
  .hero {
    background-position: right;
    padding-top: 50%;
    height: 80vh;
    overflow: hidden;
    margin-right: -5%;
    width: auto;
  }
  .hero .row {
    width: 100%;
    margin-top: -80px;
  }
  .hero #h2{
    font-size: 6px;
  }
  .hero .container {
    width: auto;
  }
  .arrows {
    display: none;
  }
}