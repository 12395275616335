.testimonal .items {
  padding: 30px;
  transition: 0.5s;
}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #dda523;
  color: #fff;
}
.testimonal .name {
  margin: 25px;
}
.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal .name span {
  color: #dda523;
}
.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #FFD95A;
  color: #000000;
  cursor: pointer;
  box-shadow: 0 5px 15px #202020;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #000000;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #dda523;
}