.Card{
  padding: 0;
  background: #f9f9f9;
}

.categoryWrapper{
  height: 310px;
  width: 420px;
  /*background: url("http://ohlookawebsite.com/bathroomtestfull.jpg") no-repeat center center;*/
  display: flex;
  justify-content: center;     /*justify see more button*/
  align-items: center;
  position: relative;
  background: linear-gradient(170deg, rgba(255, 255, 255, 0.623) 0%, rgb(233, 233, 233) 100%);
}

.image img{
  margin-bottom: 10px; /* Adjust as needed */
  width: 45%;
  height: 45%;
  max-width: 100%;
  object-fit: cover;
  display: block;
}

.categoryWrapper:after{
  position: absolute;
  top:0;
  left: 0;
  right:0;
  bottom: 0;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a29ca9+0,95909b+99 */
background: #FFD95A; /* Old browsers */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a29ca9', endColorstr='#95909b',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  content: '';
  opacity: 0;
    -webkit-transition: opacity 0.5s ease 0s; 
  box-shadow: 0 5px 15px red;
}

.categoryWrapper:hover:after{
  opacity: 0.4;
}

.categoryWrapper h2{
  color:rgb(17, 17, 17);
  font-size: 25px;
  letter-spacing: 2px;
  -webkit-transition: all 0.15s ease 0s; 
  margin-left: -210px;
  z-index: 10;
  text-align: center;
  margin-right: 8px;
}

.categoryWrapper:hover h2{
  transform: translateY(-20px);
}

.categoryWrapper button{
  position: absolute;
  transform: translatey(60px);
  -webkit-appearance: none;
  border: none;
  background: none;
  color:white;
  width: 250px;
  height:50px;
  font-size: 20px;
  padding: 0;
  margin-top: 12;
  outline: none;
  z-index: 10;
}

.categoryWrapper button span{
  display: block;
  position: relative;
  line-height: 50px;
  height: 50px;
  cursor: pointer;
}

.categoryWrapper button > span:after{
  content:'';
  position: absolute;
  top:0;
  left: 50%;
  width: 20px;
  height: 0;
  
  border: 1px solid white;
  border-left: none;
  border-bottom: none;
  
      transition: height 0.15s ease-out, width 0.15s ease-out 0.10s;
}

.categoryWrapper:hover button > span:after{
  width: calc(50% - 1px);
  height: calc(100% - 2px);
  transition: width 0.15s ease-out, height 0.15s ease-out 0.10s;
}

.categoryWrapper button > span:before{
  content:'';
  position: absolute;
  top:0;
  right: 50%;
  width: 20px;
  height: 0;
  
  border: 1px solid white;
  border-right: none;
  border-bottom: none;
  
      transition: height 0.15s ease-out, width 0.15s ease-out 0.10s;
}

.categoryWrapper:hover button > span:before{
  width: calc(50% - 1px);
  height: calc(100% - 2px);
  transition: width 0.15s ease-out, height 0.15s ease-out 0.10s;
}

.categoryWrapper button > span > span:before{
  content:'';
  position: absolute;
  bottom:0;
  right: 0%;
  width: 1px;
  height: 1px;
  opacity: 0;
  
}

.categoryWrapper:hover button > span > span:before{
  opacity: 1;
  border-bottom: 1px solid white;
  width: calc(50%);
  height: 1px;
  transition: opacity 0s ease-out 0.29s, width 0.15s ease-out 0.1s;
}

.categoryWrapper button > span > span:after{
  content:'';
  position: absolute;
  bottom:0;
  left: 0%;
  width: 1px;
  height: 1px;
  opacity: 0;
  
}

.categoryWrapper:hover button > span > span:after{
  opacity: 1;
  border-bottom: 1px solid white;
  width: calc(50%);
  height: 1px;
  transition: opacity 0s ease-out 0.29s, width 0.15s ease-out 0.1s;
}

.categoryWrapper button > span > span > span{
  transition: color 0.15s ease-out 0.3s;
  color: transparent;
}

.categoryWrapper:hover button > span > span > span{
  color:rgb(0, 0, 0);     /* button before hover text color*/
}

.categoryWrapper button > span > span > span:after{
  position: absolute;
  top:0;left:0;right:0;bottom:0;
  color:#000000;
  content: attr(data-attr-span);
  width: 0%;
  height: 100%;
  background:rgb(255, 255, 255);
  white-space: nowrap;
  text-align: center;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  transition: width 0.2s;
}

.categoryWrapper button:hover > span > span > span:after{
  width: 100%;
}
/*------------------------------- FREE label----------------------------------------*/
.free-label {
  position: absolute;
  overflow: hidden;
  width: 150px;
  height: 150px;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.free-label::before {
  content: 'FREE';
  position: absolute;
  width: 150%;
  height: 40px;
  background-image: linear-gradient(45deg, #ff6547 0%, #ffb144  51%, #ff7053  100%);
  transform: rotate(45deg) translateY(-20px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  box-shadow: 0 5px 10px rgba(0,0,0,0.23);
}

.free-label::after {
  content: '';
  position: absolute;
  width: 10px;
  bottom: 0;
  left: 0;
  height: 10px;
  z-index: -1;
  box-shadow: 140px -140px #cc3f47;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
}
/*-----------------------------------------------------------------------*/
.download-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-left: 3%;
}

.download-link:hover {
  background-color: #0056b3;
}
@media screen and (max-width: 768px) {
  .categoryWrapper {
    width: auto;
  }
  .download-link {
    margin-left: 10%;
  }
}