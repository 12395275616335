/* Always set the map height explicitly to define the size of the div
      * element that contains the map. */
#map {
  height: 100%;
  width: 100%;
}

.contacts {
  background: rgb(30,30,40);
}

.contacts h1 {
  font-size: 50px;
  text-align: center;
  padding-top: 90px;
  margin-left: -9px;
  color: #d59563;
}

/*Heading Shake Effect*/
.contacts h1:hover {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.contacts .left {
  float: left;
  width: 50%;
  height: 700px;
  background: rgb(30,30,40);
}

.contacts .right {
  float: right;
  width: 50%;
  height: 700px;
  background: rgb(30,30,40);
}

.contacts ul {
  list-style-type: none;
}

.contacts form {
  max-width: 420px;
  margin: 20px auto;
}

/*background: #13232f;*/
.contacts .formbox {
  background: transparent;
  padding: 0.1px;
  max-width: 400px;
  margin: 10px auto;
  border-radius: 1px;
  box-shadow: 0 4px 10px 4px transparent;
}

.contacts form {
  display: inline-block;
}

.contacts input.short {
  width: 48%;
  color: grey;
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  border: 2px solid #d59563;
  transition: all 0.3s;
  padding: 13px;
  margin-right: 6px;
  margin-bottom: 15px;
  box-sizing: border-box;
  outline: 0;
}

.contacts input:focus {
  /*border-bottom: 2px solid cyan;*/
}

#map-overlap {
  position: absolute;
  top: 105%;
  left: 51%;
  text-align: left;
  font-size: 17px;
  padding-top: 15px;
  padding-left: 15px;
  width: 205px;
  height: 110px;
  font-weight: 200;
  background: rgb(30,30,40);
  color: white;
}

.at {
  color: #d59563;
}


::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: dimgrey;
}

.contacts .feedback-input {
  color: grey;
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  border: 2px solid #d59563;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width: 99%;
  box-sizing: border-box;
  outline: 0;
}

.contacts .feedback-input:focus {
  border: 2px solid #d59563;
}

.contacts textarea {
  height: 150px;
  line-height: 150%;
  resize: vertical;
}

.contacts [type="submit"] {
  width: 20%;
  background: rgb(30,30,40);
  border-radius: 5px;
  cursor: pointer;
  color: #d59563;
  font-size: 16px;
  margin-left: 80%;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s;
  margin-top: -4px;
  border: 2px solid #d59563;
  font-weight: 500;
}

.contacts [type="submit"]:hover {
  background: #d59563;
  color: rgb(30,30,40);
  outline: 0 !important;
}

@media all and (max-width: 800px) {
  .contacts .left,
  .contacts .right {
    display: block;
    float: none;
    width: 100%;
  }

  .contacts .short {
    min-width: 99%;
  }
}
