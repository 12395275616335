.aboutHome {
  height: auto;
}
.aboutHome #heading {
  text-align: left;
  padding: 0;
}

.aboutHome .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.aboutHome .left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
}
.aboutHome .right {
  padding: 80px 50px;
  margin-top: 50%;
}
.aboutHome .items {
  margin-top: 50px;
}
.aboutHome .item {
  background-color: #fff;
  margin-top: 30px;
  padding: 20px;
  transition: 0.5s;
}
.aboutHome .img {
  width: 200px;
}
.aboutHome img {
  width: 70px;
  height: 70px;
}
.aboutHome .item h2 {
  font-size: 20px;
  margin-bottom: 15px;
}
.aboutHome .item p {
  color: #595959;
}
.aboutHome .item:hover {
  background-color: #FFD95A;
  color: #000000;
  cursor: pointer;
  box-shadow: 0 5px 15px #202020;
}
.aboutHome .item:hover p {
  color: #000000;
}

.left-comp {
  margin-left: -7.0rem;
  margin-top: 50%;
  top: +55.5rem;
  object-fit: cover;
  color: #6DBCEA;
}

/*---- our expertise in-------*/

.boardcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 5%;
}

.boardcontainer .gallery {
  perspective: 700px;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.boardcontainer .gallery ul {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0.3em;
  perspective-origin: center center;
  transform-style: preserve-3d;
  transform: rotateX(40deg);
  transition: all 0.4s;
}

.boardcontainer .gallery ul:hover {
  transform: translateZ(100px) rotateX(20deg);
  z-index: 999;
}

.boardcontainer .gallery ul:hover li {
  filter: grayscale(0);
  border: none;
  background-size: 300% 300%;
  box-shadow: none;
}

.boardcontainer .gallery li {
  position: absolute;
  left: 0;
  top: 0;
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  transition: transform 0.3s, filter 1s;
  background: rgb(255, 255, 255);
  background-size: 100% 100%;
  box-shadow: 0 5px 15px #202020;
}

.boardcontainer .gallery li:first-of-type { transform: none; }
.boardcontainer .gallery li:nth-of-type(2) { left: 100%; transform-origin: left center; transform: rotateY(180deg); }
.boardcontainer .gallery li:nth-of-type(3) { top: 100%; transform-origin: center top; transform: rotateX(-180deg); }
.boardcontainer .gallery li:nth-of-type(4) { left: -100%; transform-origin: right center; transform: rotateY(-180deg); }
.boardcontainer .gallery li:nth-of-type(5) { top: -100%; transform-origin: center bottom; transform: rotateX(180deg); }
.boardcontainer .gallery li:nth-of-type(6) { top: 100%; left: 100%; transform-origin: center top; transform: rotateX(-180deg); }
.boardcontainer .gallery li:nth-of-type(7) { top: 100%; left: -100%; transform-origin: right center; transform: rotateY(-180deg); }
.boardcontainer .gallery li:nth-of-type(8) { top: -100%; left: -100%; transform-origin: center bottom; transform: rotateX(180deg); }
.boardcontainer .gallery li:nth-of-type(9) { top: -100%; left: 100%; transform-origin: left center; transform: rotateY(180deg); }

.boardcontainer .gallery li:nth-of-type(2), .boardcontainer .gallery li:nth-of-type(3), .boardcontainer .gallery li:nth-of-type(4), .boardcontainer .gallery li:nth-of-type(5) { transition-delay: 0.03s; }
.boardcontainer .gallery li:nth-of-type(6), .boardcontainer .gallery li:nth-of-type(7), .boardcontainer .gallery li:nth-of-type(8), .boardcontainer .gallery li:nth-of-type(9) { transition-delay: 0s; }
.boardcontainer .gallery ul:hover li:nth-of-type(2), .boardcontainer .gallery ul:hover li:nth-of-type(3), .boardcontainer .gallery ul:hover li:nth-of-type(4), .boardcontainer .gallery ul:hover li:nth-of-type(5) { transition-delay: 0s; }
.boardcontainer .gallery ul:hover li:nth-of-type(6), .boardcontainer .gallery ul:hover li:nth-of-type(7), .boardcontainer .gallery ul:hover li:nth-of-type(8), .boardcontainer .gallery ul:hover li:nth-of-type(9) { transition-delay: 0.2s; }

.boardcontainer .gallery ul:hover li:nth-of-type(2), .boardcontainer .gallery ul:hover li:nth-of-type(4), .boardcontainer .gallery ul:hover li:nth-of-type(7), .boardcontainer .gallery ul:hover li:nth-of-type(9) { transform: rotateY(0); }
.boardcontainer .gallery ul:hover li:nth-of-type(3), .boardcontainer .gallery ul:hover li:nth-of-type(5), .boardcontainer .gallery ul:hover li:nth-of-type(6), .boardcontainer .gallery ul:hover li:nth-of-type(8) { transform: rotateX(0); }

.boardcontainer .gallery li:nth-of-type(1) { background-position: center center; }
.boardcontainer .gallery li:nth-of-type(2) { background-position: right center; }
.boardcontainer .gallery li:nth-of-type(3) { background-position: center bottom; }
.boardcontainer .gallery li:nth-of-type(4) { background-position: left center; }
.boardcontainer .gallery li:nth-of-type(5) { background-position: center top; }
.boardcontainer .gallery li:nth-of-type(6) { background-position: right bottom; }
.boardcontainer .gallery li:nth-of-type(7) { background-position: left bottom; }
.boardcontainer .gallery li:nth-of-type(8) { background-position: left top; }
.boardcontainer .gallery li:nth-of-type(9) { background-position: right top; }

.boardcontainer .gallery ul:nth-of-type(1) li { background-image: url(/src/assests/home/NXP.png); }
.boardcontainer .gallery ul:nth-of-type(1) { width: 120px; height: 80px; }
.boardcontainer .gallery ul:nth-of-type(2) li { background-image: url(/src/assests/home/imx8mm.webp); }
.boardcontainer .gallery ul:nth-of-type(2) { width: 100px; height: 100px; }
.boardcontainer .gallery ul:nth-of-type(3) li { background-image: url(/src/assests/home/variscite.png); }
.boardcontainer .gallery ul:nth-of-type(3) { width: 130px; height: 80px; }
.boardcontainer .gallery ul:nth-of-type(4) li { background-image: url(/src/assests/home/var-som.webp); }
.boardcontainer .gallery ul:nth-of-type(4) { width: 120px; height: 100px; }
.boardcontainer .gallery ul:nth-of-type(5) li { background-image: url(/src/assests/home/ST.png); }
.boardcontainer .gallery ul:nth-of-type(5) { width: 94px; height: 114px; }
.boardcontainer .gallery ul:nth-of-type(6) li { background-image: url(/src/assests/home/STM32MP1.jpg); }
.boardcontainer .gallery ul:nth-of-type(6) { width: 100px; height: 66px; }

.boardcontainer .gallery li { text-indent: 999px; overflow: hidden; }

/*-------------------------------------------------------------------------*/
.imgcontainer {
position: relative;
margin-left: 38%;
justify-content: center;
align-items: center;
max-width: 25%;
border-top: 1px solid rgba(255, 49, 49, 0.5);
border-right: 1px solid rgba(0, 255, 255, 0.5);
border-bottom: 1px solid rgba(57, 255, 20, 0.5);
border-left: 1px solid rgba(40, 129, 192, 0.5);
}

.tagimage {
max-width: 100%;
max-height: 80%;
}

@media screen and (max-width: 768px) {
  .aboutHome .container {
    flex-direction: column-reverse;
    margin-top: 30rem;
  }
  .aboutHome .right {
    padding: 0px 0px;
  }
  .aboutHome .items {
    padding: 0px;
    transition: 0.5s;
    width: auto;
  }
  .aboutHome .row {
    width: 100%;
    margin-top: 30%;
  }
  .aboutHome .container .left-comp {
    display: none;
  }
  .imgcontainer {
    margin-left: 25%;
    margin-right: 25%;
    max-width: 100%;
    max-height: 100%;
  }
  p {
    justify-content: center;
    margin-left: 5%;
  }
}