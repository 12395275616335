/* src/components/common/Loader.css */

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.penguin {
  --penguin-skin: black;
  --penguin-belly: white;
  --penguin-beak: orange;
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.penguin-top {
  top: 10%;
  left: 25%;
  background: var(--penguin-skin, gray);
  width: 50%;
  height: 45%;
  border-radius: 70% 70% 60% 60%;
}

.penguin-bottom {
  top: 40%;
  left: 23.5%;
  background: var(--penguin-skin, gray);
  width: 53%;
  height: 45%;
  border-radius: 70% 70% 100% 100%;
}

.right-hand {
  top: 5%;
  left: 25%;
  background: var(--penguin-skin, black);
  width: 30%;
  height: 60%;
  border-radius: 30% 30% 120% 30%;
  transform: rotate(130deg);
  z-index: -1;
  animation-duration: 2s;
  animation-name: wave;
  animation-iteration-count: infinite;
  transform-origin: 0% 0%;
  animation-timing-function: linear;
}

.left-hand {
  top: 0%;
  left: 75%;
  background: var(--penguin-skin, gray);
  width: 30%;
  height: 60%;
  border-radius: 30% 30% 30% 120%;
  transform: rotate(-45deg);
  z-index: -1;
}

.right-cheek {
  top: 15%;
  left: 35%;
  background: var(--penguin-belly, white);
  width: 60%;
  height: 70%;
  border-radius: 70% 70% 60% 60%;
}

.left-cheek {
  top: 15%;
  left: 5%;
  background: var(--penguin-belly, white);
  width: 60%;
  height: 70%;
  border-radius: 70% 70% 60% 60%;
}

.belly {
  top: 60%;
  left: 2.5%;
  background: var(--penguin-belly, white);
  width: 95%;
  height: 100%;
  border-radius: 120% 120% 100% 100%;
}

.right-feet {
  top: 85%;
  left: 60%;
  background: var(--penguin-beak, orange);
  width: 15%;
  height: 30%;
  border-radius: 50% 50% 50% 50%;
  transform: rotate(-80deg);
  z-index: -2222;
}

.left-feet {
  top: 85%;
  left: 25%;
  background: var(--penguin-beak, orange);
  width: 15%;
  height: 30%;
  border-radius: 50% 50% 50% 50%;
  transform: rotate(80deg);
  z-index: -2222;
}

.right-eye {
  top: 45%;
  left: 60%;
  background: black;
  width: 15%;
  height: 17%;
  border-radius: 50%;
}

.left-eye {
  top: 45%;
  left: 25%;
  background: black;
  width: 15%;
  height: 17%;
  border-radius: 50%;
}

.sparkle {
  top: 25%;
  left: 15%;
  background: white;
  width: 35%;
  height: 35%;
  border-radius: 50%;
}

.blush-right {
  top: 65%;
  left: 15%;
  background: pink;
  width: 15%;
  height: 10%;
  border-radius: 50%;
}

.blush-left {
  top: 65%;
  left: 70%;
  background: pink;
  width: 15%;
  height: 10%;
  border-radius: 50%;
}

.beak-top {
  top: 60%;
  left: 40%;
  background: var(--penguin-beak, orange);
  width: 20%;
  height: 10%;
  border-radius: 50%;
}

.beak-bottom {
  top: 65%;
  left: 42%;
  background: var(--penguin-beak, orange);
  width: 16%;
  height: 10%;
  border-radius: 50%;
}

.penguin * {
  position: absolute;
}

.penguin {
  animation-duration: 10s;
  animation-name: loop;
  animation-iteration-count: infinite;
  transform-origin: 0% 0%;
  animation-timing-function: linear;
}

@keyframes wave {
  10% {
    transform: rotate(110deg);
  }
  30% {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(110deg);
  }
  80% {
    transform: rotate(130deg);
  }
  100% {
    transform: rotate(110deg);
  }
}

@keyframes loop {
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(-10deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  75% {
    transform: rotateY(10deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}


.loader {
  margin-top: 1.3%;
  position: relative;
  font-size: 2em;
  background: #fff;
  color: #000;
  mix-blend-mode: difference;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.3em;
}

.loader span {
  color: #fff;
  mix-blend-mode: difference;
}

.loader:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 100%;
  background: #000;
  animation: animate 3s linear infinite;
}

@keyframes animate {
  0% {
    left: 0;
  }
  50% {
    left: calc(100% - 80px);
  }
  100% {
    left: 0;
  }
}