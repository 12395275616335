/* This file is for individual blogs*/
.info {
    position: relative;
    width: 80%;
    color: #000000;
    margin-left: 10%;
  }
  
  .info .info-body {
  padding: 30px;
  font-size: 16px; /* Adjust font size */
  text-align: left;
  background: rgba(255, 255, 255, 0.7);
  text-align: justify;
  }
  .info img {
  width: 60%;
  height: auto;
  object-fit: cover;
  margin: auto;
  margin-left: 20%;
  }
  
  .info .info-body p{
    padding: 30px;
    font-size: 16px; /* Adjust font size */
    text-align: left;
    background: rgba(255, 255, 255, 0.7);
  }
  .info .info-body h2 {
    text-align: left;
    margin-left: 28px;
  }
  .info .info-body ol {
  line-height: 30px;
  font-size: 16px;
  text-align: left;
  }
  .info .info-body ol li {
    list-style-type: disc; /* Add this line to display bullet points */
    margin-left: 80px;
    text-justify: unset;
  }
  .info .info-body code {
    font-family: monospace;
    background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 5px;
    display: inline-block;
    white-space: pre-wrap;
  }

/* Styling for contact form */
.contact-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: 800px;
    width: 700px;
    margin-left: 10%;
    margin-top: 20px;
  }
  
  .contact-form h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .contact-form form {
    display: grid;
    grid-gap: 10px;
  }
  
  .contact-form label {
    font-weight: bold;
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .contact-form button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  @media screen and (max-width: 768px) {
    .info {
      width: auto;
    }
    .info img {
      width: 100%;
      margin-left: 0;
  }
    .info-body {
      width: auto;
    }
    .contact-form {
      width: 100%;
    }
  }